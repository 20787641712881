import React from "react";
import axios from "axios";
import vCardJS from "vcards-js";
import { decode } from "html-entities";
import SocialMedia from "../components/SocialMedia";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Review: React.FC<Props> = ({ clientData, generalSettings }) => {
  const sitePath = process.env.REACT_APP_HOST;
  const basePath = process.env.PUBLIC_URL;

  const getBase64FromUrl = async (url: any) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <>
      <div className="align-middle fixed inset-0 z-10 overflow-y-auto">
        <div className="relative flex flex-col justify-around theme-bg-image h-[100vh] w-full p-4 mx-auto rounded-md !opacity-100">
          <div className="flex flex-col justify-center">
            {clientData.logo_url && (
              <div className=" min-w-[10vw] max-w-[50vw] mx-auto z-10">
                <img
                  alt="Logo"
                  src={clientData.logo_url}
                  className="max-h-[17vh]"
                />
              </div>
            )}
            <div
              className="text-center text-600 theme-button-background theme-text-color z-10"
              dangerouslySetInnerHTML={{
                __html: decode(clientData.Title_tag_WebSite),
              }}
            ></div>
          </div>
          {clientData.is_business === "1" && (
            <a
              href={clientData.googlereview_URL}
              target="_blank"
              rel="noreferrer"
              className="z-10 cursor-pointer mx-auto"
            >
              <img
                src={`${basePath}/images/google_review.png`}
                height={200}
                width={200}
                alt="Google Review"
              />
            </a>
          )}
          <div>
            <div className="flex relative flex-row justify-center gap-2 mb-2 z-10">
              {clientData.icon_Instagram_active === "1" &&
                clientData.Client_Instagram_URL && (
                  <a
                    className="bottom-icon "
                    href={clientData.Client_Instagram_URL}
                  >
                    {
                      <img
                        src="https://zaags.com/wp-content/uploads/2025/01/instgramcolour.png"
                        width="100px"
                        height="100px"
                        alt="icon"
                      />
                    }
                  </a>
                )}
              {clientData.icon_Facebook_active === "1" &&
                clientData.Client_Facebook_URL && (
                  <a
                    className="bottom-icon"
                    href={clientData.Client_Instagram_URL}
                  >
                    {
                      <img
                        src="https://zaags.com/wp-content/uploads/2025/01/facebookcolour.png"
                        width="100px"
                        height="100px"
                        alt="icon"
                      />
                    }
                  </a>
                )}
            </div>
            <button
              className="w-80 h-10 md:h-12 relative theme-button-background theme-text-color border-2 theme-border-color py-4 px-4 rounded-full flex items-center justify-center z-10 mx-auto mb-4 "
              onClick={() =>
                (window.location.href = `${sitePath}${basePath}/${clientData.app_id}`)
              }
            >
              Go to the app
            </button>

            <div className="w-80 h-10 md:h-12 relative theme-button-background theme-text-color border-2 theme-border-color py-4 px-4 rounded-full flex items-center justify-center z-10 mx-auto mb-4 group ">
              {clientData.icon_vcard_active === "1" && (
                <>
                  <p
                    onClick={async (e: any) => {
                      const vCard = vCardJS();

                      const base64Img: any = await getBase64FromUrl(
                        clientData.vCard_logo
                      );

                      const b64 = base64Img.replace(
                        /^data:image.+;base64,/,
                        ""
                      );

                      vCard.firstName = clientData.vCard_first_name
                        ? clientData.vCard_first_name
                        : clientData.contact_first_name;
                      vCard.lastName = clientData.vCard_last_name
                        ? clientData.vCard_last_name
                        : clientData.contact_last_name;
                      vCard.organization = clientData.vCard_business_name;
                      vCard.photo.embedFromString(b64, "JPEG");
                      vCard.workPhone = clientData.vCard_phone_number
                        ? clientData.vCard_phone_number
                        : clientData.busines_phonenumber;
                      vCard.cellPhone = clientData.vCard_mobile_phone
                        ? clientData.vCard_mobile_phone
                        : clientData.contact_mobilenumber;
                      vCard.email = clientData.vCard_email_id
                        ? clientData.vCard_email_id
                        : clientData.contat_email;
                      vCard.workAddress.street = clientData.vCard_address;
                      vCard.url = clientData.vCard_website;
                      vCard.title =
                        clientData.vCard_choose_business_cat === "1"
                          ? clientData.vCard_business_cat
                          : "";
                      const element = document.createElement("a");
                      const file = new Blob([vCard.getFormattedString()], {
                        type: "text/vcard;charset=utf-8",
                      });
                      element.href = URL.createObjectURL(file);
                      element.download =
                        navigator.platform === "iPhone"
                          ? `${clientData.zaagsclient_name}.ics`
                          : `${clientData.zaagsclient_name}.vcf`;
                      document.body.appendChild(element);
                      element.click();
                    }}
                  >
                    Upload Business Card
                  </p>
                  <img
                    className="icon-color mx-4"
                    src={generalSettings.icon_vcard_image}
                    height={25}
                    width={25}
                    alt=""
                  />
                  {/*  <RWebShare
                    data={{
                      text: "Share",
                    }}
                  >
                    {generalSettings.icon_share_app && (
                      <img
                        src={generalSettings.icon_share_app}
                        width={40}
                        height={40}
                        alt="share"
                        className="icon-color cursor-pointer  group-hover:block relative w-6"
                      ></img>
                    )}
                  </RWebShare> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
